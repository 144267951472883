import { render, staticRenderFns } from "./PhysicalServe.vue?vue&type=template&id=cbec61c0&scoped=true&"
import script from "./PhysicalServe.vue?vue&type=script&lang=js&"
export * from "./PhysicalServe.vue?vue&type=script&lang=js&"
import style0 from "./PhysicalServe.vue?vue&type=style&index=0&id=cbec61c0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cbec61c0",
  null
  
)

export default component.exports