<template>
    <div class="container-fluid">
        <Head></Head>
        <div class="row">
            <div class="col-lg-12" style="top: -180px;overflow: hidden;">
                <div class="container_phy">
                    <h1 class="phy_title phy02">{{$t('PhysicalServeMessage.PhysicalServe1')}}</h1>
                </div>
            </div>
            <div class="col-lg-12" style="height: 680px;width: 1980px;overflow: hidden">
                <div class="col-lg-6" style="float: left;height: 680px">
                    <div class="phy_middle_left">
                        <div class="phy_middle_left_title">
                            <h4 class="phy02">Logistics Management</h4>
                        </div>
                        <div class="phy_middle_left_middle">
                            <p class="phy03">Ours Logistics team uses efficient resources – right from packing at your
                                end to our
                                facility for processing, swiftly and securely.</p>
                        </div>
                        <div class="phy_middle_left_last">
                            <p class="phy03">Our logistics solutions include:</p>
                            <ul>
                                <li class="phy03">Dedicated Lifespan trucks for pick-up</li>
                                <li class="phy03">Customized all-weather shipping containers</li>
                                <li class="phy03">Shipping totes and bins</li>
                                <li class="phy03">Trusted partnerships with white-glove logistic providers</li>
                                <li class="phy03">Chain of Custody services – efficient equipment tracking system</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6" style="float: left;height: 680px">
                    <div class="phy_middle_right">
                        <img alt=""
                             src="../../../assets/image/phy/1.webp">
                    </div>
                </div>
            </div>
        </div>
        <Bottom style="margin-left: -35px"></Bottom>
    </div>
</template>

<script>
    import {Bottom, Head} from "@/view/layout/commponents";

    export default {
        name: "PhysicalServe",
         metaInfo: {
        meta: [{                 
            name: '暹诺回收官网,手机回收,笔记本回收,数码产品回收,企业回收',
            content: '上海暹诺环保科技有限公司 (简称: 暹诺环保©)是中国领先的环保解决方案提供商，为国内500多家商业和工业企业客户提供回收服务。我们投资开发电子垃圾解决方案，用以适应不断变化的世界。目前该领域不仅包括处理和回收，还包括确保敏感数据的安全的方案，以帮助我们的客户实现零废物的绿色目标。作为中国领先的电子废弃物管理服务供应商，我们的服务宗旨是在最大限度降低影响的同时，又能最大限度地提高资源价值，为所有利益相关者促进经济和环境的可持续发展。'
        }]
        },
        components: {Bottom, Head},
        created() {
            $(function () {

                $('.phy02').animate({
                    opacity: '1',
                    top: '15px'
                }, 1000)


                $(window).scroll(function () {
                    var s = $(window).scrollTop();
                    console.log(s)
                    if (s > 200) {
                        $('.phy03').animate({
                            opacity: '1',
                        }, 2000)
                    }
                })
            })


        }
    }


    $(function () {

        $('.phy02').animate({
            opacity: '1',
            top: '15px'
        }, 1000)


        $(window).scroll(function () {
            var s = $(window).scrollTop();
            console.log(s)
            if (s > 200) {
                $('.phy03').animate({
                    opacity: '1',
                }, 2000)
            }
        })
    })


</script>

<style scoped>
    .phy02 {
        opacity: 0;
        position: relative;
    }

    .phy03 {
        opacity: 0;
    }

    .container_phy {
        height: 80px;
        width: 100%;

        text-align: center;
    }

    .container_phy .phy_title {
        color: black;
        font-weight: 800;
    }

    .col-lg-6 {

    }

    .col-lg-6 .phy_middle_left {
        height: 475px;
        width: 70%;

        position: relative;
        left: 25%;
        margin-top: -120px;
    }

    .phy_middle_left .phy_middle_left_title {
        height: 75px;
        width: 100%;

        margin-top: 150px;
    }

    .phy_middle_left_title h4 {
        color: black;
        font-weight: 600;
        font-size: 19px;
        line-height: 75px;
    }

    .phy_middle_left_middle {
        height: 80px;
        width: 100%;
        


    }

    .phy_middle_left_middle p {
        color: #0d1011;
        line-height: 25px;
        font-size: 16px;
    }

    .phy_middle_left_last {
        height: 295px;
        width: 100%;

        margin-top: -18px ;

    }

    .phy_middle_left_last p {
        color: #1a1a1a;
        margin-top: 25px;
        font-size: 17px;
    }

    .phy_middle_left_last ul {
        display: inline-block;
        height: 260px;
        width: 100%;
        overflow: hidden;

    }

    .phy_middle_left_last ul li {
        float: left;
        height: 40px;
        width: 85%;
        text-align: left;
        margin-left: 25px;
        color: #333333;
        font-size: 14px;

    }

    .phy_middle_left_last ul li::before {
        content: "";
        height: 6px;
        width: 6px;
        background-color: black;
        border-radius: 50%;
        position: absolute;
        margin-left: -8px;
        margin-top: 7px;
        opacity: 0.7;

    }


    .phy_middle_right img {
        height: 660px;
        width: 680px;
    }

</style>